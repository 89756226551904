import React, { Component } from "react";
import { StaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";
import ReactHtmlParser from "react-html-parser";
import SEO from "../components/seo";
import Layout from "../components/layout";
import rover1 from "../images/rover.png";

const Partners = ({ location }) => {

  const getLogos = (node, index) => {
    
    if (node.type === 'tag' && node.name === 'div' && node.children[0] && node.children[0].type === 'tag' && node.children[0].name === 'figure') {
      console.log(index, node)
      // if (node.children[0].children[0].name === 'a') {
      //   console.log('in link')
      //   // console.log(index, node.children[0])
      //   const name = node.children[0].children[0].data
      //   const link = node.children[0].children[0].attribs.href
      //   const srcUrl = node.children[0].children[0].children[0].attribs.src
      //   return <a href={link}><img src={srcUrl} width={300} height={200} key={`img-${index}`}/></a>
      // }
      if (node.children[0].children[0].name === 'img') {
        console.log('in image')
        const name = node.children[0].children[0].data
        const srcUrl = node.children[0].children[0].attribs.src
        return <img src={srcUrl} key={`img-${index}`} className="sm:mb-12 md:max-w-xs" style={{paddingRight: '40px', marginBottom: '20px', width:'100%', maxWidth: '15rem'}}/>
      }
    } else {
      return null
    }
  }

const getLinks = (node, index) => {
  // console.log(index, node)
  if (node.type === 'tag' && node.name === 'p' && node.children[0] && node.children[0].type === 'tag' && node.children[0].name === 'a') {
    console.log(index, node.children[0])
    const name = node.children[0].children[0].data
    const link = node.children[0].attribs.href
    return <p key={`link${index}`}><a href={link} target="_blank" key={link}>{name}</a></p>
  } else {
    return null
  }
}

  return (
    <StaticQuery
    query={graphql`
    query {
      wpgraphql {
        page(id: "/partners/", idType: URI) {
          content
          title
          slug
          uri
        }
      }
    }
    `}
      render={data => 
        <Layout location={location}>
          <SEO
            keywords={[`virtualmoon`, `vr`, `moon`, `space`]}
            title="Partners"
          />
          <section id="intro" className="text-left pt-24 sm:ml-5 md:pl-20 md:ml-32">
            <h1 className="text-white font-title font-thin text-4xl mb-5">{data.wpgraphql.page.title}</h1>
            <div className="text-white font-body sm:w-full md:w-5/6  md:flex flex-wrap items-start object-contain">{ ReactHtmlParser(data.wpgraphql.page.content, { transform: getLogos })  }</div>;
            <h2 className="text-white font-title font-thin text-3xl mb-5">Links</h2>
            <div className="text-white font-body md:w-5/6 sm:w-full">{ ReactHtmlParser(data.wpgraphql.page.content, { transform: getLinks })  }</div>;
            <div></div>
          </section>
        </Layout>
      }
    />)
}


Partners.propTypes = {
  location: PropTypes.object
};


export default Partners;

